<template>
    <div class="">

       <div class="alert text-center alert-dismissible fade show" :class="variant" role="alert" v-if="variant!='quizell-alert'">
         
          <div  class="d-flex align-items-center flex-md-nowrap flex-wrap justify-content-lg-between justify-content-center">
              <div class="">
                  <!-- <strong>Attention:</strong>  -->
                  {{ text }}
              </div>
              <button class="btn " v-if="isEmailVerificationPending" @click="sendVerificationEmail">
                <span v-if="!isSendVerificationEmailLoading" class="quizell-anchor">Send verification Email</span>
                          <div
                            v-else
                            class="spinner-border spinner-border-sm text-white"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
              </button>
              <span v-else class="quizell-anchor" @click="openUpgradePlan">Upgrade</span>
              
          </div>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
    </button>
     </div>
   
      <div v-else class="alert quizell-alert text-center alert-dismissible fade show" role="alert">
        Hurray! Weblly is Installed 
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
      </div>
    </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            isSendVerificationEmailLoading:false
        }
    },
props:{
    text:String,
    variant:String,
    isEmailVerificationPending:Boolean
},
computed:{
    ...mapGetters(["getNavbarData","getUserAllAccountData"]),
     UserAccountData() {
      return this.getNavbarData;
    },
    getUserType() {
      return this.getNavbarData.userType;
    },

},
methods:{
      openUpgradePlan(){

        let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
        let url = window.location.origin + `/manage/account/pricing`
            window.open(url,"_self")
      }
     
      if (this.getUserType == "wix") {
        let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`;
        window.open(url, "_blank");
      }
      if (this.getUserType == "shopify") {
    //     const envPath=process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
    //   const  url= `https://${this.UserAccountData.name}${envPath}manage/subscription/plans`
    //   window.open(url,"_parent")
    const token = localStorage.getItem('user_access_token')
        
        if(token){
          window.open(`https://api.quizell.com/manage/subscription/upgrade/plans?token=${token}`,"_blank")
        }
      }
},
async sendVerificationEmail(){
    try {
        this.isSendVerificationEmailLoading = true
        let response =await axios.get(`/resend/verify`)

        if(response.status == 200){
            this.$toasted.show("Verification email sent successfully!", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        }
        
    } catch (error) {
        if(error.response){
            this.$toasted.show("Something went wrong! Please try again.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        }
        
    } finally {
        this.isSendVerificationEmailLoading = false
    }
}
}
}
</script>

<style scoped>
.quizell-anchor{
    color:#fff;
    font-weight: 600;
    cursor:pointer;
    /* text-decoration: underline; */
}
.quizell-anchor:hover{
  
    text-decoration: underline;
}
.quizell-warning-alert{
    background: #f35b04;
    color:  #fff;

}
.quizell-danger-alert{
    background: #d90429;
    color:  #fff;

}
.quizell-alert{
    background:#4d1b7e;
    color:#fff;
}
.update-btn{
    background:transparent;
    color:#4d1b7e;
    border: 1px solid #4d1b7e;
    border-radius: 20px;
    transition: 0.3s ease all;
    /* box-shadow: 0px 2px 2px 3px rgba(172, 171, 171, 1); */
    text-wrap: nowrap;
}
.update-btn span{
     border: 1px solid #4d1b7e;
     padding: 0px 4px;
    border-radius: 50%;
     background:#4d1b7e ;
   color: aliceblue;
   transition: 0.3s ease all;
}
.update-btn:hover{
    background:#4d1b7e ;
    color: #FFA201;
     transition: 0.3s ease all;
}
.update-btn:hover > span{
 background:#FFA201 ;
 color:#4d1b7e
}
.update-btn:focus{
    box-shadow: none !important;
}
</style>